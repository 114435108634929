// eslint-disable-next-line
/* eslint-disable */
import axios from 'axios'
import {message} from 'antd';

const service = axios.create({
  // baseURL: '',
  timeout: 20000, // request timeout
  validateStatus: function (status: any) {
    return status >= 200 && status < 600;
  },
});

service.interceptors.request.use(
  (config: any) => {
    return config
  },
)

// response interceptor
service.interceptors.response.use(
  (response: any) => {
   if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
    //  console.log('----->');
      message.error(response?.data?.msg || 'service exception')
      return Promise.reject(response.data);
    } else {
      if(response?.data?.code < 0) {
        message.error(response?.data?.msg)
        return Promise.reject(response.data);
      }
      return response.data.body || response.data;
    }
  },
  (error: any) => {
    // message.error(error?.data?.msg)
    return Promise.reject(error)
  }
)

export default service;