import service from './request';

export default {
    sendEmail: (params)=>{
        return service({
            url: '/api/go/sales_lead',
            data: params,
            method: 'post'
        })
    }
}