import React from "react"
import Home from '../components//Home'
import Seo from "../components/seo"

export const Head = () => <Seo title="Home" />

const IndexPage = () => {

  return (
    <Home />
  )
}

export default IndexPage
