import React,{ useEffect, useState} from "react"
import Layout from "../../components/layout"
import './index.css'
import API from '../../api'
import { Message } from "antd"

import img_bg1 from "../../images/home/1@2x.png";
import img_bg2 from "../../images/home/2@2x.png";
import img_bg3 from "../../images/home/3@2x.png";
import img_bg4 from "../../images/home/4@2x.png";
import img_bg5 from "../../images/home/5@2x.png";
import img_bg6 from "../../images/home/6@2x.png";
import img_bg7 from "../../images/home/7@2x.png";
import img_bg8 from "../../images/home/8@2x.png";
import ico_email from "../../images/icons/email@2x.png";

export default  () => {
    
    const [email, _email] = useState('');
    const [sending, _sending] = useState(false);
    useEffect(()=>{

    }, [])
    
    const submit = async() => {
        if(!email){
            Message.warning('Please enter your Email address');
            return ;
        }
        if(!email.includes('@')){
            Message.warning('Please enter the correct email address');
            return ;
        }
        if(sending) return ;
        _sending(true);
        try{
            await API.sendEmail({
                email
            })
            Message.success('Thank you for your submission , our team will reach out to you soon');
        }catch(err){}
        _sending(false);
    }
    return (
        <Layout>
            <div className="page-home">
                <img className="w-[100%]" src={img_bg1}/>
                <img className="w-[100%]" src={img_bg2}/>
                <img className="w-[100%]" src={img_bg3}/>
                <img className="w-[100%]" src={img_bg4}/>
                <img className="w-[100%]" src={img_bg5}/>
                <img className="w-[100%]" src={img_bg6}/>
                <div className="home-email">
                    <img className="w-[100%]" src={img_bg7}/>
                    <div >
                        <div className="home-email-box">
                            <img src={ico_email} className="w-[24px] h-[24px]" />
                            <input placeholder="Please enter your Email" onChange={e=>{
                                _email(e.target.value);
                            }}/>
                            <div className="btn" onClick={submit}>Submit</div>
                        </div>
                    </div>
                </div>
                <img className="w-[100%]" src={img_bg8}/>
            </div>
        </Layout>
    )
}
